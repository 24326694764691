<template>
  <div class="elv-accounts-derivative-page-container">
    <p class="elv-accounts-derivative__title">{{ t('title.position') }}</p>
    <AccountScreening
      ref="derivativeScreeningRef"
      table-type="Derivative"
      @onChangeDerivativeFilter="onChangeDerivativeFilter"
      @onChangeAccountFilter="onChangeDerivativeFilter"
    >
    </AccountScreening>

    <div :style="{ height: tableHeight }">
      <TableList
        ref="processTableRef"
        type="accounts"
        height="calc(100% - 6px)"
        :date-time="derivativeScreeningRef?.dateTimeValue"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import TableList from './components/TableList.vue'
import { useEntityStore } from '@/stores/modules/entity'
import AccountScreening from '../components/Screening.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

const { t } = useI18n()
const route = useRoute()

const processTableRef = ref()
const derivativeScreeningRef = ref()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const tableConfigStore = useReportsTableConfigStore()

const tableHeight = computed(() => {
  return `calc(100% - 100px)`
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const onChangeDerivativeFilter = async () => {
  try {
    const date = derivativeScreeningRef.value?.dateTimeValue
    const subGroup = derivativeScreeningRef.value?.subGroup
    const group = derivativeScreeningRef.value?.group
    const params: any = {
      date,
      group,
      subGroup
    }
    await tableConfigStore.getProcessTableConfig('derivative', [], 'left', params)
    processTableRef.value?.resetList()
  } catch (error: any) {
    console.log(error)
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-accounts-derivative') {
      try {
        tableConfigStore.processTableConfig = []
        const derivativeFilter =
          accountStore.derivativeFilterList.find((item: any) => {
            return item.entityId === entityId.value
          }) ?? {}
        const params = {
          date: dayjs().tz(entityStore.entityDetail?.timezone).format('YYYY-MM-DD'),
          group: 'DERIVATIVE_TYPE',
          subGroup: 'ENTITY_ACCOUNT',
          ...derivativeFilter?.data
        }
        await tableConfigStore.getProcessTableConfig('derivative', [], 'left', params)
        nextTick(() => {
          processTableRef.value?.resetList()
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  tableConfigStore.processTableConfig = []
  accountStore.agGridApi = null
  accountStore.agGridColumnApi = null
})
</script>

<style lang="scss" scoped>
:deep(.elv-financial-process-table) {
  padding-top: 0px;
}

.elv-accounts-derivative-page-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
}

.elv-accounts-derivative__title {
  margin: 24px 0 6px 23px;
  font-family: 'Plus Jakarta Sans';
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}
</style>
